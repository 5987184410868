import React from 'react';
import { Box, Typography,  Card,  CardContent, CardMedia } from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import LuggageIcon from '@mui/icons-material/Luggage';

import CheckIcon from '@mui/icons-material/Check';
function CarCard({ price, details }) {
    return (

        <Box sx={{ width: '100%',  alignSelf:'center' }} display="flex" flexDirection={'column'} alignItems="center" justifyContent="center">
            <Card
                sx={{
                    boxShadow: 4,
                    borderRadius: 2,
                    width: '100%',
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
                }}
            >
                <CardMedia
                    component="img"
                    height="140"
                    // image={'./2.jpg'}
                    image={`${process.env.PUBLIC_URL}/2.jpg`}
                    alt={'TripGo economy '}
                    sx={{ objectFit: 'contain', width: '40%' }}
                />
                <CardContent sx={{ width: '60%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <Typography align="center" sx={{ fontWeight: 'bold', fontSize: 18 }}>
                        Nissan X-Trail
                    </Typography>
                    <Box >
                        {/* <Typography  align="left" sx={{ color: '#aaa', mb:2,}}>
                        {details}€
                    </Typography> */}
                        <Typography variant="h5" align="left" sx={{ fontWeight: 'bold', color: '#d2042a', mb: 0, }}>
                            {price}€
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={'column'} sx={{ mt: 0, width:'100%' }}>
                        <Box display="flex" alignItems="center" my={0.5}>
                            <PersonIcon color="disabled" />
                            <Typography variant="body2" marginLeft={0.5}>Up to 3 + 1 child</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" my={0.5}>
                            <LuggageIcon color="disabled" />
                            <Typography variant="body2" marginLeft={0.5}>Up to 3</Typography>
                        </Box>
                        <Box display="flex" alignItems="center"  my={0.5}>
                            <CheckIcon color="disabled" />
                            <Typography variant="body2" marginLeft={0.5}>Free cancellation</Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>

    );
}

export default CarCard;
