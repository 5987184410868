import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';
function Footer() {

    return (
        <Box>

            <Box
                component="footer"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    padding: 3,
                    backgroundColor:"#1a2f40",
                    // background: 'linear-gradient(135deg, rgba(0, 0, 30, 0.9), rgba(20, 20, 60, 0.8), rgba(50, 50, 90, 0.7))', // Enhanced gradient
                    color: '#ffffff', // White text for readability
                    borderTop: '1px solid rgba(255, 255, 255, 0.2)', // Subtle border at the top
                    fontSize: '14px',
                    mt: 'auto', // Ensures footer sticks to the bottom
                    fontFamily: '"Open Sans", sans-serif' // Apply Open Sans font
                }}
            >
                {/* Business Information */}
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <Typography
                            component="img"
                            src={`${process.env.PUBLIC_URL}/whiteLogo.png`}
                            alt="TripGo Logo"
                            sx={{
                                height: 30,
                                mb: 2,         // Set a fixed height for the logo
                                width: 'auto',      // Allow width to adjust based on aspect ratio
                                objectFit: 'contain', // Maintain aspect ratio within the set height
                                maxWidth: '150px',  // Optional: Set a max width to avoid oversizing
                                fontFamily: '"Open Sans", sans-serif' // Apply Open Sans font
                                // ml:50
                            }}
                        />
                    </Box>
                    <Typography sx={{ color: "#ddd" }}>Business permit: 10166361/0</Typography>
                    <Typography sx={{ color: "#ddd" }}>TVA: LU36009186</Typography>
                    <Typography sx={{ color: "#ddd", mt: 2 }}>hello@tripgo.lu</Typography>
                    <Typography sx={{ color: "#999", mt: 1 }}>TripGo @ 2025</Typography>
                </Box>

                {/* Social Media Icons */}
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                    <Box>
                        <Link href="https://www.facebook.com/profile.php?id=61567890161671" target="_blank" underline="none">
                            <IconButton sx={{ color: '#ffffff' }}>
                                <Facebook />
                            </IconButton>
                        </Link>

                        <Link href="https://www.instagram.com/tripgoluxembourg/" target="_blank" underline="none">
                            <IconButton sx={{ color: '#ffffff' }}>
                                <Instagram />
                            </IconButton>
                        </Link>
                    </Box>

                </Box>
            </Box>

        </Box>


    );
}

export default Footer;
