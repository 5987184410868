import React, { useEffect, useRef, useState } from 'react';
import { Container, Box, Typography, Button, Link, IconButton, Stepper, Step, StepLabel, Alert, Modal, Fab, Fade, LinearProgress, AppBar, Grid } from '@mui/material';
import { Typewriter } from 'react-simple-typewriter';
import Footer from './components/Footer';
import TripInfoAddressForm from './components/FirstStep/TripInfoAddressForm';
import Book from './components/SecondStep/Book';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import ManageBooking from './components/ManageBooking';
// import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
// import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
function HomePage() {
    // const { bookingId } = useParams(); // Extract bookingId from the URL
    // alert(bookingId)
    // console.log("bookingId ", bookingId)
    const bookingRef = useRef(null);
    const [activeStep, setActiveStep] = useState(0);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [operationType, setOperationType] = useState("Booking");

    const [isLoading, setIsloading] = useState(false)

    // Main state to hold trip data throughout the steps
    const [tripData, setTripData] = useState({
        pickup: '',
        dropoff: '',
        date: '',
        time: '',
        details: '',
        price: null,
        distance: '',
        fullName: '',
        phoneNumber: '',
        emailAddress: '',
        leaveNote: '',
        paymentMethod: '',
        status: ''

    });

    const [booking, setBooking] = useState({})

    const steps = ['Calculate Price', 'Checkout'];

    // Validation function to check if all fields in TripInfo are filled
    const isTripInfoComplete = () => {
        const { pickup, dropoff, date, time, price } = tripData;
        if (!pickup || !dropoff || !date || !time || price === null) {
            setError("Please complete all fields in 'Trip Info' before proceeding.");
            return false;
        }
        setError('');
        return true;
    };

    const handleNext = () => {
        // Validate TripInfo fields before moving to the next step
        if (activeStep === 0 && !isTripInfoComplete()) {
            return; // Stop here if validation fails
        }
        if (activeStep === steps.length - 1) {
            setOpen(true);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (isModalOpen)
            setTripData({
                pickup: '',
                dropoff: '',
                date: '',
                time: '',
                details: '',
                price: null,
                distance: '',
                fullName: '',
                phoneNumber: '',
                emailAddress: '',
                leaveNote: '',
                paymentMethod: '',
                status: ''
            });
        setActiveStep(0)
    }, [isModalOpen])

    // useEffect(() => {
    //     if (location.state?.bookingNumber) {
    //         setBookingNumber(location.state.bookingNumber);
    //         setIsModalOpen(true); // Open modal if booking number is present
    //     }
    // }, [location.state]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    return (
        <ThemeProvider theme={theme}>
            <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        zIndex:1,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "80%",
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        textAlign: 'center',
                    }}
                >
                    <CheckCircleIcon sx={{ fontSize: 60, color: '#fead16' }} />
                    <Typography variant="h4">Booking Confirmed!</Typography>
                    <Typography>Your booking code is <Typography sx={{ fontWeight: 'bold', fontSize: 44, color: '#fead16' }}>{booking.BookingCode}</Typography></Typography>
                    <Button variant="outlined" sx={{ mt: 2, color: "#fead16", borderColor:"#fead16" }} onClick={handleCloseModal}>Close</Button>
                </Box>
            </Modal>
            <AppBar position="sticky">
                {isLoading && <LinearProgress />}
            </AppBar>
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100vh"
                sx={{
                    // backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#FFFFFF',
                    backgroundColor: '#e7e8f3'
                }}
            >
                <Box
                    sx={{
                        position: "fixed",
                        display: { xs: 'block', md: 'none' },
                        bottom: "10px", // Adjust as needed
                        left: "10px", // Adjust as needed
                        zIndex: 1000, // Ensure it's on top of other elements
                    }}
                >
                    <Fab
                        color="success"
                        href="https://wa.me/352661392929" // Replace with your WhatsApp link
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            backgroundColor: "#25D366",
                            "&:hover": {
                                backgroundColor: "#1EBE54",
                            },
                        }}
                    >
                        <WhatsAppIcon />
                    </Fab>
                </Box>

                {/* Header */}
                <Box sx={{ display: 'flex', zIndex:1, justifyContent: 'space-around', p: 1, m: 1 }}>
                    <Typography
                        component="img"
                        src={`${process.env.PUBLIC_URL}/logo.png`}
                        alt="Company Logo"
                        sx={{
                            zIndex:1,
                            height: 60,
                            width: 'auto',
                            objectFit: 'contain',
                            maxWidth: '150px'
                        }}
                    />
                    <Link href="https://wa.me/352661392929" target="_blank" underline="none" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: 5, display: { xs: 'none', md: 'block' }, }}>
                        <IconButton sx={{ color: 'green' }}>
                            <WhatsAppIcon />
                            <Typography variant="body1" sx={{ textAlign: 'center', padding: '10px', fontWeight: 'bold' }}>
                                +352 661 39 29 29
                            </Typography>
                        </IconButton>
                    </Link>
                </Box>

                {/* Main Content */}
                <Container component="main" sx={{ flexGrow: 1, my: 4, display: 'flex', overflow: 'hidden', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                    {/* Error message if TripInfo validation fails */}

                    {/* <Box sx={{width:'50%', 
                        top:-100, 
                        left:-20,
                         borderBottomRightRadius:300,
                         transform:'rotate(6deg)',
                         height:'40%',
                         backgroundColor:'#d2042a' ,
                         position:'absolute',
                         zIndex:0}}>

                         </Box>  */}
                    {/* Typewriter Effect Text */}
                    <Typography variant="h4" sx={{ mb: 2, textAlign: 'center',padding: '10px', borderRadius: '8px', color: '#1a2f40', width: '100%', fontFamily:'Coiny'}}>
                    24/7 Airport Shuttle Service
                    </Typography>


                    <Grid container spacing={2} sx={{ maxWidth: { xs: '90%', md: '800px' } }}>
                        {["/Findel.jpg", "/Brussels.jpg", "/Frankfurt.jpg"].map((src, index) => (
                            <Grid item xs={12} sm={4} key={index}>
                                <Box
                                onClick={()=> operationType == "Booking"  && bookingRef.current.scrollIntoView({ behavior: "smooth" })}
                                    component="img"
                                    src={src}
                                    alt={`Shuttle service ${index + 1}`}
                                    sx={{
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "8px",
                                        boxShadow: 3,
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Typography variant="h6" sx={{ mb: 2, textAlign: 'justify', padding: '10px', borderRadius: '8px', color: '#1a2f40', width:{xs:'90%', md:'70%'} }}>
                        We offer reliable and affordable 24/7 airport shuttle services to and from Luxembourg Findel Airport, as well as Brussels, Frankfurt, and Charleroi airports. Travel comfortably and stress-free with our professional service.
                    </Typography>

                    {/* Action Buttons */}
                    <Box sx={{ mb: 2 }}>
                        <Button
                            onClick={() => { setOperationType("Booking") }}
                            variant={operationType == "Booking" ? "contained" : "outlined"} sx={operationType == "Booking" ? { m: 1, borderRadius: 4, backgroundColor: '#fead16' } : { m: 1, borderRadius: 4, color: '#fead16', borderColor:'#fead16' }}>Book Now</Button>
                        <Button
                            onClick={() => { setOperationType("Manage") }}
                            variant={operationType != "Booking" ? "contained" : "outlined"} sx={operationType != "Booking" ? { m: 1, borderRadius: 4, backgroundColor: '#fead16' } : { m: 1, borderColor:'#fead16', borderRadius: 4, color: '#fead16' }}>Manage Booking</Button>
                    </Box>

                    {/* Stepper for the Wizard */}
                    {operationType == "Booking" ?
                        <Box ref={bookingRef} sx={{ width: '100%', maxWidth: 800, backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: 3, borderRadius: '12px', boxShadow: 3, }}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label, index) => (
                                    <Step
                                        sx={{
                                            '& .MuiStepLabel-root .Mui-completed': {
                                                color: '#fead16', // circle color (COMPLETED)
                                            },
                                            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                            {
                                                color: '#fead16', // Just text label (COMPLETED)
                                            },
                                            '& .MuiStepLabel-root .Mui-active': {
                                                color: '#fead16', // circle color (ACTIVE)
                                            },
                                            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                            {
                                                color: '#fead16', // Just text label (ACTIVE)
                                            },
                                            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                                fill: 'common.white', // circle's number (ACTIVE)
                                            },
                                        }}
                                        key={index}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

                            <Box sx={{ mt: 1, position: 'relative' }}>
                                {activeStep === 0 && (
                                    <Fade in={activeStep === 0} timeout={500}>
                                        <div>
                                            <TripInfoAddressForm tripData={tripData} setTripData={setTripData} setError={setError} setIsloading={setIsloading} />
                                        </div>
                                    </Fade>
                                )}
                                {activeStep === 1 && (
                                    <Fade in={activeStep === 1} timeout={500}>
                                        <div>
                                            <Book
                                                tripData={tripData}
                                                setTripData={setTripData}
                                                setSuccessModal={setIsModalOpen}
                                                setBooking={setBooking}
                                                setIsloading={setIsloading}
                                                isLoading={isLoading}
                                            />
                                        </div>
                                    </Fade>
                                )}
                            </Box>

                            {/* Navigation Buttons */}
                            <Box display="flex" justifyContent={activeStep == 0 ? "flex-end" : 'flex-start'} mt={0}>
                                {activeStep > 0 && (
                                    <Button variant="outlined" onClick={handleBack} sx={{ color: "#d2042a", mt:1, borderColor:"#d2042a"}}>
                                        Back
                                    </Button>
                                )}
                                {activeStep == 0 && (
                                    <Button variant="contained" color="primary" onClick={handleNext} sx={{ alignSelf: 'flex-end', width: { xs: '100%', md: '10%' }, backgroundColor: '#fead16', py: 1.4 }}>
                                        {'Next'}
                                    </Button>
                                )}
                            </Box>
                        </Box>
                        :
                        <Box sx={{ width: '100%', maxWidth: 800, minHeight: 380, backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: 3, borderRadius: '12px', boxShadow: 3, }}>
                            <ManageBooking setIsloading={setIsloading} />
                        </Box>
                    }
                </Container>

                {/* Footer */}
                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default HomePage;


