import React, { useState } from 'react';
import {
    Box,
    TextField,
    Alert,
    Modal,
    Button,
    Typography,
} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import TripSummary from './SecondStep/TripSummary';
import TripClient from './SecondStep/TripClient';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import CheckIcon from '@mui/icons-material/Check';
const ManageBooking = ({ setIsloading }) => {
    const theme = useTheme();
    const [BookingCode, setBookingCode] = useState('');
    const [fullName, setFullName] = useState('');
    const [tripData, setTripData] = useState(null);
    const [error, setError] = useState('');
    const [deleteError, setDeleteError] = useState('');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [successDelete, setSuccessDelete] = useState(false)

    const deleteBooking = async () => {
        setIsloading(true);
        try {
            const response = await axios.patch(`${process.env.REACT_APP_SOS_ERROR}bookings/${BookingCode}`);
            setIsloading(false);
            setIsModalOpen(false);
            setTripData(null);
            setDeleteError('');
            setSuccessDelete(true);
            setBookingCode('');
            setFullName('');
            setTimeout(() => {
            }, 500);
            setTimeout(() => {
                setSuccessDelete(false);
            }, 5000);
        } catch (err) {
            setTimeout(() => {
                setIsloading(false)
            }, 500);
            if (err.response) {
                setDeleteError(err.response.data.error || 'Error deleting booking, contact us via WhatsApp');
                setIsModalOpen(false);
            } else {
                setDeleteError('Unable to delete booking, contact us via WhatsApp');
                setIsModalOpen(false);
            }
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const fetchBooking = async () => {
        setIsloading(true)
        try {

            const response = await axios.get(`${process.env.REACT_APP_SOS_ERROR}bookings/`
                , {
                    params: { BookingCode, fullName },
                });
            setTimeout(() => {
                setIsloading(false)
            }, 500);

            if (response.data.booking) {
                setTripData(response.data.booking);
                setError('');

            }
            else
                setError("No booking found")
        } catch (err) {
            setTimeout(() => {
                setIsloading(false)
            }, 500);
            if (err.response) {
                setError(err.response.data.error || 'Error fetching booking');
            } else {
                setError('Unable to fetch booking details');
            }
            setTripData(null);
        }
    };


    return (
        tripData != null ?
            <Box elevation={3} sx={{ mt: -1, borderRadius: 2, maxWidth: { xs: '100%' }, display: 'flex', flexDirection: 'column' }}>
                {deleteError && <Alert severity="error" sx={{ mt: 2 }}>{deleteError}</Alert>}
                <TripSummary tripData={tripData} />
                <TripClient tripData={tripData} showEditIcon={false} />

                <Button variant="contained" color="primary" onClick={() => setIsModalOpen(true)} sx={{ alignSelf: 'flex-end', width: { xs: '100%' }, backgroundColor: '#d2042a', mt: 2, py: 1.4 }}>
                    {'Cancel booking'}
                </Button>

                <Modal open={isModalOpen} onClose={handleCloseModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: "80%",
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            boxShadow: 24,
                            p: 4,
                            textAlign: 'center',
                        }}
                    >
                        <SentimentDissatisfiedIcon sx={{ fontSize: 60, color: '#d2042a' }} />
                        <Typography variant={isMobile ? 'h6' : 'h4'}>Are you sure you want to cancle your booking</Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 44, color: '#d2042a' }}>{BookingCode}</Typography>
                        <Button variant="contained" sx={{ mt: 2, backgroundColor: "#d2042a" }} onClick={deleteBooking}>Confirm</Button>
                        <Button variant="outlined" sx={{ mt: 2, color: "#d2042a", mx: 2, border: "1px solid #d2042a" }} onClick={handleCloseModal}>go Back</Button>
                    </Box>
                </Modal>

            </Box>
            :
            <Box elevation={3} sx={{ mt: 2, padding: 2, borderRadius: 2, maxWidth: { xs: '100%' }, display: 'flex', flexDirection: 'column' }}>

                {successDelete &&
                    <Alert icon={<CheckIcon fontSize="inherit" />} sx={{ mb: 2 }} severity="success">
                        Your Booking has been cancled successfuly.
                    </Alert>
                }


                <TextField
                    label="Booking code"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'grey', // Default border color
                            },
                            '&:hover fieldset': {
                                borderColor: '#fead16', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#fead16', // Border color when focused
                            },

                        },
                        '& .MuiInputLabel-root': {
                            color: 'grey', // Default label color
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                            color: '#fead16', // Label color when focused
                        },
                        '& .MuiInputLabel-root:hover': {
                            color: '#fead16', // Label color on hover
                        },
                    }}
                    variant="outlined"
                    name="bookingCode"
                    value={BookingCode}
                    onChange={(e) => { setError(''); setBookingCode(e.target.value) }}
                // fullWidth


                />

                <TextField
                    sx={{
                        mt: 1,
                        "& .MuiInputBase-input": {
                            textTransform: "uppercase",
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'grey', // Default border color
                            },
                            '&:hover fieldset': {
                                borderColor: '#fead16', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#fead16', // Border color when focused
                            },

                        },
                        '& .MuiInputLabel-root': {
                            color: 'grey', // Default label color
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                            color: '#fead16', // Label color when focused
                        },
                        '& .MuiInputLabel-root:hover': {
                            color: '#fead16', // Label color on hover
                        },
                    }}
                    label="Full name"
                    variant="outlined"
                    name="fullname"
                    value={fullName}
                    onChange={(e) => { setError(''); setFullName(e.target.value) }}
                />
                {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                <Button variant="contained" color="primary" onClick={fetchBooking} sx={{ alignSelf: 'flex-end', width: { xs: '100%', md: '10%' }, backgroundColor: '#fead16', mt: 2, py: 1.4 }}>
                    {'Next'}
                </Button>
            </Box>

    );
};

export default ManageBooking;
