import React, { useEffect, useState, useRef } from 'react';
import { Container, Box, Typography, Button, Link, IconButton, Alert, Modal, Fab, LinearProgress, AppBar } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Typewriter } from 'react-simple-typewriter';
import Footer from './components/Footer';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TripSummary from './components/SecondStep/TripSummary';
import TripClient from './components/SecondStep/TripClient';
import CheckIcon from '@mui/icons-material/Check';
function CancelPage() {
    const myRef = useRef(null);
    const { BookingCode } = useParams(); // Extract bookingId from the URL

    let navigate = useNavigate();
    const [successDelete, setSuccessDelete] = useState(false)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [deleteError, setDeleteError] = useState('');
    const [tripData, setTripData] = useState(null);
    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const deleteBooking = async () => {
        setIsloading(true);
        try {
            const response = await axios.patch(`${process.env.REACT_APP_SOS_ERROR}bookings/${BookingCode}`)
            setIsloading(false);
            setIsModalOpen(false);
            setTripData(null);
            setDeleteError('');
            setSuccessDelete(true);

            setTimeout(() => {
            }, 500);
            setTimeout(() => {
                setSuccessDelete(false);
                navigate('/');
            }, 5000);
        } catch (err) {
            setTimeout(() => {
                setIsloading(false)
            }, 500);
            if (err.response) {
                setDeleteError(err.response.data.error || 'Error deleting booking, contact us via WhatsApp');
                setIsModalOpen(false);
            } else {
                setDeleteError('Unable to delete booking, contact us via WhatsApp');
                setIsModalOpen(false);
            }
        }
    };

    const fetchBookingByCode = async (BookingCode) => {
        setIsloading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_SOS_ERROR}bookings/fetchBookingByCode`
                , {
                    params: { BookingCode },
                });
            console.log(response.data)
            setTimeout(() => {
                setIsloading(false)
            }, 500);

            if (response.data.booking) {
                setTripData(response.data.booking);
                setError('');
            }
            else
                navigate('/404');

        } catch (err) {
            setTimeout(() => {
                setIsloading(false)
            }, 500);
            if (err.response) {
                setError(err.response.data.error || 'Error occurred while fetching the booking.');
            } else {
                setError('Unable to fetch booking details');
            }
            setTripData(null);
        }
    };

    useEffect(() => {
        if (tripData && error == "")
            setTimeout(() => {
                myRef.current.scrollIntoView({ behavior: "smooth" });
            }, 500);
    }, [tripData])

    useEffect(() => {
        if (BookingCode)
            fetchBookingByCode(BookingCode)
    }, [BookingCode]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="sticky">
                {isLoading && <LinearProgress />}
            </AppBar>
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100vh"
                sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#FFFFFF',
                    backgroundColor: '#e7e8f3'
                }}
            >
                <Box
                    sx={{
                        position: "fixed",
                        display: { xs: 'block', md: 'none' },
                        bottom: "10px", // Adjust as needed
                        left: "10px", // Adjust as needed
                        zIndex: 1000, // Ensure it's on top of other elements
                    }}
                >
                    <Fab
                        color="success"
                        href="https://wa.me/352661392929" // Replace with your WhatsApp link
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            backgroundColor: "#25D366",
                            "&:hover": {
                                backgroundColor: "#1EBE54",
                            },
                        }}
                    >
                        <WhatsAppIcon />
                    </Fab>
                </Box>

                {/* Header */}
                <Box sx={{ display: 'flex', justifyContent: 'space-around', p: 1, m: 1 }}>
                    <Typography
                        component="img"
                        src={`${process.env.PUBLIC_URL}/logo.png`}
                        alt="Company Logo"
                        sx={{
                            height: 60,
                            width: 'auto',
                            objectFit: 'contain',
                            maxWidth: '150px'
                        }}
                    />
                    <Link href="https://wa.me/352661392929" target="_blank" underline="none" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: 5, display: { xs: 'none', md: 'block' }, }}>
                        <IconButton sx={{ color: 'green' }}>
                            <WhatsAppIcon />
                            <Typography variant="body1" sx={{ textAlign: 'center', padding: '10px', fontWeight: 'bold' }}>
                                +352 661 39 29 29
                            </Typography>
                        </IconButton>
                    </Link>
                </Box>

                {/* Main Content */}
                <Container component="main" sx={{ flexGrow: 1, my: 4, display: 'flex', overflow: 'hidden', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                    {/* Typewriter Effect Text */}
                    <Typography variant="h4" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold', padding: '10px', borderRadius: '8px', color: '#fead16', width: '100%' }}>
                        TripGo Luxembourg is {' '}
                        <span style={{ color: '#fff', fontWeight: '500', color: '#d2042a' }}>
                            <Typewriter
                                words={['Reliable!', 'Affordable!', 'Fast!']}
                                loop={false}
                                cursor
                                cursorStyle="|"
                                typeSpeed={50}
                                deleteSpeed={50}
                                delaySpeed={1000}
                            />
                        </span>
                    </Typography>

                    {/* Action Buttons */}
                    {successDelete &&
                        <>
                            <Alert icon={<CheckIcon fontSize="inherit" />} sx={{ mb: 2 }} severity="success">
                                Your Booking has been cancled successfuly.
                            </Alert>
                            <Typography sx={{ color: "#999" }}>
                                You will be redirected to the homepage in a few seconds!
                            </Typography>
                        </>
                    }

                    {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

                    {tripData != null &&
                        <Box sx={{ width: '100%', maxWidth: 800, minHeight: 380, backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: 3, borderRadius: '12px', boxShadow: 3, }}>
                            <Box elevation={3} sx={{ mt: -1, borderRadius: 2, maxWidth: { xs: '100%' }, display: 'flex', flexDirection: 'column' }}>
                                {deleteError && <Alert severity="error" sx={{ mt: 2 }}>{deleteError}</Alert>}
                                <TripSummary tripData={tripData} />
                                <TripClient tripData={tripData} showEditIcon={false} />

                                <Button ref={myRef} variant="contained" color="primary" onClick={() => setIsModalOpen(true)} sx={{ alignSelf: 'flex-end', width: { xs: '100%' }, backgroundColor: '#d2042a', mt: 2, py: 1.4 }}>
                                    {'Cancel booking'}
                                </Button>

                                <Modal open={isModalOpen} onClose={handleCloseModal}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: "80%",
                                            bgcolor: 'background.paper',
                                            borderRadius: 2,
                                            boxShadow: 24,
                                            p: 4,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <SentimentDissatisfiedIcon sx={{ fontSize: 60, color: '#d2042a' }} />
                                        <Typography variant={isMobile ? 'h6' : 'h4'}>Are you sure you want to cancle your booking</Typography>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: 44, color: '#d2042a' }}>{BookingCode}</Typography>
                                        <Button variant="contained" sx={{ mt: 2, backgroundColor: "#d2042a" }} onClick={deleteBooking}>Confirm</Button>
                                        <Button variant="outlined" sx={{ mt: 2, color: "#d2042a", mx: 2, border: "1px solid #d2042a" }} onClick={handleCloseModal}>go Back</Button>
                                    </Box>
                                </Modal>
                            </Box>
                        </Box>
                    }
                </Container>

                {/* Footer */}
                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default CancelPage;


