import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Divider,
    Card,
    CardActionArea,
    CardMedia,
    CardContent
} from '@mui/material';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import LuggageIcon from '@mui/icons-material/Luggage';
import NotAccessibleIcon from '@mui/icons-material/NotAccessible';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CarCard from '../Shared/CarCard';

const TripSummary = ({ tripData, car }) => {
    return (
        <Paper elevation={3} sx={{ mt: 2, padding: 2, borderRadius: 2, maxWidth: { xs: '100%' } }}>
            <Box display="flex" flexDirection={'column'} alignItems="flex-start" marginBottom={1}>
                <Box display="flex" alignItems="center" marginBottom={1}>
                    <TripOriginIcon sx={{ color: '#fead16', fontSize: '1.5rem' }} />
                    <Typography variant="body1" fontWeight="bold" color="text.primary" sx={{ pl: 2 }}>
                        {tripData.pickup}
                    </Typography>
                </Box>

                <MoreVertIcon sx={{ marginLeft: -0.5, paddingRight: 1, color: '#fead16', fontSize: '2rem' }} />

                <Box display="flex" alignItems="center" marginBottom={1}>
                    <LocationOnIcon sx={{ color: '#fead16', fontSize: '1.5rem' }} />
                    <Typography variant="body1" fontWeight="bold" color="text.primary" sx={{ pl: 2 }}>
                        {tripData.dropoff}
                    </Typography>
                </Box>
            </Box>

            <Divider sx={{ marginY: 1 }} />

            <Box display="flex" alignItems="center" sx={{ my: 2 }}>
                <CalendarMonthIcon sx={{ color: '#fead16', fontSize: '1.5rem' }} />
                <Typography variant="body1" fontWeight="bold" color="text.primary" sx={{ pl: 2 }}>
                    {tripData.date}
                </Typography>
            </Box>

            <Box display="flex" alignItems="center" marginBottom={2}>
                <AccessTimeIcon sx={{ color: '#fead16', fontSize: '1.5rem' }} />
                <Typography variant="body1" fontWeight="bold" color="text.primary" sx={{ pl: 2 }}>
                    {tripData.time}
                </Typography>
            </Box>

            <Box
                paddingY={1}
                // paddingX={2}
                borderRadius={1}
                sx={{   }}
            >
               <CarCard price={tripData.price}/>
            </Box>
        </Paper>
    );
};

export default TripSummary;
