import React, { useEffect, useState, useRef } from 'react';
import {
    Box, TextField, RadioGroup, FormControlLabel, Radio, Typography, Paper, Grid, Button, Checkbox, Modal
} from '@mui/material';

// import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'

import { Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';


// const stripePromise = loadStripe('pk_test_51QFyssP5IRdUEFhgAiBBsj6RI6mngPHfwWgBsHiBxlmgj1gUYduDSX3tVK3nN2l6Zoj0afHoZD82i7aHkmSNH8Ml00qd0r8C6N');
import TripSummary from './TripSummary';
import TripClient from './TripClient';
import TermsAndConditions from './TermsAndCondition';
// Add this function within Book.js
const createBooking = async (tripData, paymentMethod, status) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SOS_ERROR}bookings`,{
            ...tripData,
            paymentMethod,
            status,
        });
        console.log('Booking created successfully:', response.data);
        return { status: true, booking: response.data }
    } catch (error) {
        console.error('Error creating booking:', error);
        alert(error);
        return { status: false, booking: {} }
    }
};
function Book({ tripData, setTripData, setSuccessModal, setBooking, setIsloading, isLoading }) {
    const myRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            myRef.current.scrollIntoView({ behavior: "smooth" });
        }, 500);
    }, [])

    const [open, setOpen] = useState(false);
    const [agreed, setAgreed] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAgreeChange = (event) => {
        setAgreed(event.target.checked);
    };


    const [formData, setFormData] = useState({
        fullName: '',
        phoneNumber: '',
        emailAddress: '',
        leaveNote: ''
    });
    const [errors, setErrors] = useState({});
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [clientSecret, setClientSecret] = useState(null);
    // const [isLoading, setIsLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(true);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' }); // Clear errors on change
        setTripData({ ...tripData, [e.target.name]: e.target.value });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phoneNumber: value });
        setErrors({ ...errors, phoneNumber: '' }); // Clear error on change
        setTripData({ ...tripData, phoneNumber: value });
    };

    const validateForm = () => {
        let newErrors = {};

        // Full Name validation: required, at least 3 characters, and at least 2 words
        if (!formData.fullName.trim()) {
            newErrors.fullName = 'Full name is required';
        } else if (formData.fullName.trim().length < 3) {
            newErrors.fullName = 'Full name must be at least 3 characters long';
        } else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)+$/.test(formData.fullName.trim())) {
            newErrors.fullName = 'Please enter at least a first and last name';
        }

        // Phone Number validation: required and valid phone number format
        if (!formData.phoneNumber) {
            newErrors.phoneNumber = 'Phone number is required';
        } else if (!/^(\+\d{1,3})?\d{7,14}$/.test(formData.phoneNumber)) {
            newErrors.phoneNumber = 'Invalid phone number format';
        }

        // Email Address validation: required and valid email format
        if (!formData.emailAddress.trim()) {
            newErrors.emailAddress = 'Email address is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.emailAddress)) {
            newErrors.emailAddress = 'Invalid email address';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
        if (e.target.value === 'visa') {
            fetchClientSecret();
        } else {
            setClientSecret(null); // Clear clientSecret if "Cash" is selected
        }
    };

    const fetchClientSecret = async () => {
        try {
            const response = await fetch('http://localhost:3001/create-payment-intent', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ amount: Math.round(tripData.price * 100) })
            });
            const data = await response.json();
            setClientSecret(data.clientSecret);
        } catch (error) {
            console.error('Error fetching client secret:', error);
        }
    };

    const handleCashSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsEditable(false);
            if (paymentMethod === 'cash') {
                setIsloading(true)
                createBooking(tripData, paymentMethod, 'Pending').then(res => {
                    if (res.status) {
                        setIsloading(false)
                        setSuccessModal(true);
                        setBooking(res.booking);
                    }
                    else
                        setIsloading(false)
                }); // Call createBooking if cash payment

                // setSuccessModal(true);
            }
        }
    };

    const handleEdit = () => {
        setIsEditable(true);
    };

    return (
        <Box>
            {/* <Typography variant="h6" sx={{ color: "#666" }}>Confirm and Checkout</Typography> */}

            <TripSummary tripData={tripData} />

            {/* Information Form */}
            {isEditable &&
                <Paper ref={myRef} elevation={3} sx={{ padding: 2, borderRadius: 2, maxWidth: { xs: '100%' }, mx: 'auto', mt: 2 }}>
                    <Typography variant="h6" gutterBottom>Information Form</Typography>
                    <Box component="form" display="flex" flexDirection="column" gap={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    sx={{
                                        // "& .MuiInputBase-input": {
                                        //     textTransform: "uppercase",
                                        // },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'grey', // Default border color
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#fead16', // Border color on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#fead16', // Border color when focused
                                            },

                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'grey', // Default label color
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#fead16', // Label color when focused
                                        },
                                        '& .MuiInputLabel-root:hover': {
                                            color: '#fead16', // Label color on hover
                                        },
                                    }}
                                    label="Full Name"
                                    variant="outlined"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    fullWidth
                                    error={!!errors.fullName}
                                    helperText={errors.fullName}
                                    disabled={!isEditable}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PhoneInput
                                    preferredCountries={['lu', 'fr', 'de']}
                                    country={'lu'}
                                    // regions={'europe'}
                                    value={formData.phoneNumber}
                                    onChange={handlePhoneChange}
                                    inputProps={{
                                        name: 'phoneNumber',
                                        required: true,
                                        style: { width: '100%' }
                                    }}
                                    inputStyle={{ width: '100%' }}
                                    specialLabel="Phone Number"
                                    enableAreaCodes={false} // Disable area codes to avoid multiple entries
                                    disableDropdown={false}

                                    countryCodeEditable={false}
                                    placeholder="Enter phone number"
                                    isValid={(value) => !!value || errors.phoneNumber}
                                    disabled={!isEditable}
                                />
                                {errors.phoneNumber && (
                                    <Typography color="error" variant="caption">
                                        {errors.phoneNumber}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Email Address"
                                    variant="outlined"
                                    name="emailAddress"
                                    value={formData.emailAddress}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    error={!!errors.emailAddress}
                                    helperText={errors.emailAddress}
                                    disabled={!isEditable}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'grey', // Default border color
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#fead16', // Border color on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#fead16', // Border color when focused
                                            },

                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'grey', // Default label color
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#fead16', // Label color when focused
                                        },
                                        '& .MuiInputLabel-root:hover': {
                                            color: '#fead16', // Label color on hover
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Leave a Note"
                                    variant="outlined"
                                    name="leaveNote"
                                    value={formData.leaveNote}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    disabled={!isEditable}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'grey', // Default border color
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#fead16', // Border color on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#fead16', // Border color when focused
                                            },

                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'grey', // Default label color
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#fead16', // Label color when focused
                                        },
                                        '& .MuiInputLabel-root:hover': {
                                            color: '#fead16', // Label color on hover
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="button"
                            variant={isEditable ? "contained" : "outlined"}
                            color={isEditable ? "primary" : "primary"} // Primary for Proceed, Secondary for Edit
                            onClick={() => {
                                if (isEditable) {
                                    if (validateForm())
                                        setIsEditable(false);
                                } else handleEdit()
                            }

                            }
                            startIcon={isEditable ? <></> : <EditIcon />} // Check icon for Proceed, Edit icon for Edit
                            sx={{
                                backgroundColor: '#fead16',
                                mt: 2,
                                paddingX: 3,
                                paddingY: 1,
                                fontWeight: "bold",
                                fontSize: "1rem",
                                transition: "all 0.3s ease", // Smooth transition for color and icon
                            }}
                        >
                            <Tooltip title={isEditable ? "Click to proceed with the next step" : "Click to edit your details"}>
                                {isEditable ? 'Next' : 'Edit'}
                            </Tooltip>
                        </Button>
                    </Box>
                </Paper>
            }
            {!isEditable &&
                <TripClient tripData={tripData} onEdit={handleEdit} showEditIcon={true} />
            }

            {/* Payment Method Selection */}
            {!isEditable &&
                <Paper elevation={3} sx={{ padding: 2, mt: 2, borderRadius: 2, maxWidth: { xs: '100%' }, filter: isEditable ? 'blur(2px)' : 'none', opacity: isEditable ? 0.8 : 1, }}>
                    <Typography variant="h6" gutterBottom>Payment Method</Typography>
                    <RadioGroup
                        name="paymentMethod"
                        value={paymentMethod}
                        onChange={handlePaymentMethodChange}
                        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                    >
                        <FormControlLabel disabled={isEditable} value="cash" control={<Radio />} label="Cash" />
                        <FormControlLabel disabled={isEditable || true}
                            value="visa"
                            control={<Radio />}
                            label={
                                <Box display="flex" alignItems="center" gap={1}>
                                    Card
                                    <img src={"./credit-card.png"} alt="Visa" style={{ width: 30, height: 'auto' }} />
                                </Box>
                            }
                        />
                    </RadioGroup>

                    {/* Conditionally Render Stripe Checkout Form */}
                    {/* {paymentMethod === 'visa' && clientSecret && (
                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                            <StripeCheckoutForm isLoading={isLoading} setIsLoading={setIsLoading} tripData={tripData} paymentMethod={paymentMethod} setSuccessModal={setSuccessModal} agreed={agreed} />
                        </Elements>
                    )} */}
                    {paymentMethod === 'cash' && (

                        <form onSubmit={handleCashSubmit}>
                            <Box

                                mt={2} sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body2" color="textSecondary">
                                    Please proceed to pay in cash at the time of delivery or service.
                                </Typography>

                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={agreed}
                                                onChange={handleAgreeChange}
                                            // color="primary"
                                            />
                                        }
                                        label={
                                            <Typography variant="body2">
                                                I agree to the{" "}
                                                <span
                                                    style={{
                                                        color: "blue",
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={handleOpen}
                                                >
                                                    terms and conditions
                                                </span>
                                            </Typography>
                                        }
                                    />

                                    {/* Modal for Terms and Conditions */}
                                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                width: { xs: '80%', md: 400 },
                                                bgcolor: "background.paper",

                                                boxShadow: 24,
                                                p: 4,
                                                // mr:2,
                                                maxHeight: '80%',
                                                overflow: 'scroll'
                                            }}
                                        >
                                            <TermsAndConditions />
                                            <Button variant="contained" sx={{ mt: 2, backgroundColor: '#d2042a' }} onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Box>
                                    </Modal>
                                </div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={!agreed}
                                    // color={isEditable ? "primary" : "secondary"} // Primary for Proceed, Secondary for Edit
                                    startIcon={<CheckIcon />} // Check icon for Proceed, Edit icon for Edit
                                    sx={{
                                        backgroundColor: "#fead16",
                                        mt: 2,
                                        paddingX: 3,
                                        paddingY: 1.5,
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                        transition: "all 0.3s ease", // Smooth transition for color and icon
                                    }}
                                >

                                    {isLoading ? 'Processing...' : `Book now`}

                                </Button>
                            </Box>
                        </form>
                    )}


                </Paper>
            }
        </Box>
    );
}


// function StripeCheckoutForm({ isLoading, setIsLoading, tripData, paymentMethod, setSuccessModal, agreed }) {
//     const stripe = useStripe();
//     const elements = useElements();
//     const [isCardVerified, setIsCardVerified] = useState(false);

//     const handleStripeSubmit = async (event) => {
//         let bookingResults = await createBooking(tripData, paymentMethod, 'Paid'); 

//         console.log("Results >>>>> ", bookingResults)
//         return
//         event.preventDefault();
//         if (!stripe || !elements) return;

//         setIsLoading(true);

//         const { error, paymentIntent } = await stripe.confirmPayment({
//             elements,
//             confirmParams: {
//                 return_url: 'http://localhost:3000', // Replace with your actual return URL if needed
//             },
//             redirect: "if_required" // Prevents automatic redirection to return_url
//         });

//         if (error) {
//             alert("Error occurred during payment processing.");
//             console.error(error.message);
//             alert('Payment failed. Please try again.');
//             await createBooking(tripData, paymentMethod, 'fail'); 
//         } else if (paymentIntent && paymentIntent.status === 'succeeded') {
//             setSuccessModal(true);
//             console.log('Payment successful');
//             // await updatePayment(tripData, paymentMethod, 'Paid'); // Create booking if payment is successful
//         } else {
//             alert("Payment requires additional action.");
//             console.log("Payment status:", paymentIntent.status);
//         }

//         setIsLoading(false);
//     };

//     const handleElementChange = (event) => {
//         setIsCardVerified(event.complete); // Checks if the card details are complete and valid
//     };

//     return (
//         <Box
//             component="form"
//             onSubmit={handleStripeSubmit}
//             sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}
//         >
//             <PaymentElement
//                 options={{ layout: 'tabs' }}
//                 onChange={handleElementChange}
//             />
//             <Button
//                 sx={{
//                     backgroundColor:"#5b6df7",
//                     mt: 2,
//                     paddingX: 3,
//                     paddingY: 1.5,
//                     fontWeight: "bold",
//                     fontSize: "1rem",
//                     transition: "all 0.3s ease",
//                 }}
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 disabled={!stripe || isLoading || !isCardVerified || !agreed} // Disable button if Stripe, card verification, or loading is incomplete
//             >
//                 {isLoading ? 'Processing...' : `Pay ${tripData.price}€`}
//             </Button>
//         </Box>
//     );
// }



export default Book;
