import React, { useRef, useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { v4 as uuidv4 } from 'uuid';
import '../../styles/AutoComplete.css';
const libraries = ['places'];

function AddressAutocomplete({ label, placeholder, onAddressSelect }) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Replace with your actual Google API Key
        libraries,
    });
    const autocompleteRef = useRef(null);
    const [sessionToken, setSessionToken] = useState(null);

    // Function to create a new session token
    const createNewSessionToken = () => {
        setSessionToken(uuidv4()); // Generate a new session token using uuid
    };

    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place && place.formatted_address) {
            onAddressSelect(place.formatted_address);
            setSessionToken(null); // Reset the session token after an address is selected
        }
    };

    return (
        <>
            {isLoaded && (
                <Autocomplete
                    onLoad={(autocomplete) => {
                        autocompleteRef.current = autocomplete;
                        createNewSessionToken(); // Initialize a session token when the autocomplete loads
                    }}
                    onPlaceChanged={handlePlaceChanged}
                    options={{ sessionToken, componentRestrictions: { country: ["LU", "DE", "FR", "BE"] }, }} // Pass the session token to Google Places API
                >
                    <TextField
                        autoComplete="new-password"
                        label={label}
                        variant="outlined"
                        fullWidth
                        placeholder={placeholder}
                        // autoComplete="off" // Disable browser autocomplete
                        onFocus={createNewSessionToken} // Create a new session token on focus
                        sx={{
                            mb: 2,

                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'grey', // Default border color
                                },
                                '&:hover fieldset': {
                                    borderColor: '#fead16', // Border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#fead16', // Border color when focused
                                },
                                
                            },
                            '& .MuiInputLabel-root': {
                                    color: 'grey', // Default label color
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#fead16', // Label color when focused
                                },
                                '& .MuiInputLabel-root:hover': {
                                    color: '#fead16', // Label color on hover
                                },

                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LocationOnIcon
                                     sx={{
                                        color: '#fead16', // Default icon color
                                        '&:hover': {
                                            color: '#fead16', // Icon color on hover
                                        },
                                        '&.Mui-focused': {
                                            color: '#fead16', // Icon color when focused
                                        },
                                    }} 
                                     />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Autocomplete>
            )}
        </>
    );
}

export default AddressAutocomplete;
