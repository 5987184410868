import React from 'react';
import {
    Box,
    Typography,
    Paper,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

const TripClient = ({ tripData, onEdit, showEditIcon }) => {
    return (
        <Paper elevation={3} sx={{ mt: 2, padding: 2, borderRadius: 2, maxWidth: { xs: '100%' }, position: 'relative' }}>
            {/* Edit Button */}
            {showEditIcon && <EditIcon
                onClick={onEdit}
                sx={{
                    color: '#666',
                    fontSize: '1.5rem',
                    position: 'absolute',
                    right: 20,
                    border: '1px solid #666',
                    padding: 1,
                    borderRadius: 2,
                    cursor: 'pointer',
                }}
            />
            }

            {/* Non-Editable Information */}
            <Box display="flex" alignItems="center" marginBottom={1}>
                <PersonIcon sx={{ color: '#fead16', fontSize: '1.5rem' }} />
                <Typography variant="body1" fontWeight="bold" color="text.primary" sx={{ pl: 2 }}>
                    {tripData.fullName}
                </Typography>
            </Box>

            <Box display="flex" alignItems="center" marginBottom={1}>
                <PhoneAndroidIcon sx={{ color: '#fead16', fontSize: '1.5rem' }} />
                <Typography variant="body1" fontWeight="bold" color="text.primary" sx={{ pl: 2 }}>
                    {tripData.phoneNumber}
                </Typography>
            </Box>

            <Box display="flex" alignItems="center" marginBottom={1}>
                <AlternateEmailIcon sx={{ color: '#fead16', fontSize: '1.5rem' }} />
                <Typography variant="body1" fontWeight="bold" color="text.primary" sx={{ pl: 2 }}>
                    {tripData.emailAddress}
                </Typography>
            </Box>

            {tripData.leaveNote && (
                <Box display="flex" alignItems="center" marginBottom={1}>
                    <SpeakerNotesIcon sx={{ color: '#fead16', fontSize: '1.5rem' }} />
                    <Typography variant="body1" fontWeight="bold" color="text.primary" sx={{ pl: 2 }}>
                        {tripData.leaveNote}
                    </Typography>
                </Box>
            )}
        </Paper>
    );
};

export default TripClient;
